import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

const FixedTableBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="true">
      {block?.table_rows?.length > 0 && (
        <div className="mt-8 overflow-x-auto prose prose-lg max-w-none">
          <table className="min-w-[768px] w-full">
            <thead>
              {block?.table_rows?.map(
                (row, index) =>
                  index === 0 && (
                    <tr>
                      <th className="w-[20%]">{row.column_1}</th>
                      <th className="w-[20%]">{row.column_2}</th>
                      <th className="w-[30%]">{row.column_3}</th>
                      <th className="w-[30%]">{row.column_4}</th>
                    </tr>
                  )
              )}
            </thead>
            <tbody>
              {block?.table_rows?.map(
                (row, index) =>
                  index > 0 && (
                    <tr>
                      <td>
                        <Richtext text={row.column_1} />
                      </td>
                      <td>
                        <Richtext text={row.column_2} />
                      </td>
                      <td>
                        <Richtext text={row.column_3} />
                      </td>
                      <td>
                        <Richtext text={row.column_4} />
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      )}
    </BlockWrapper>
  )
}

export default FixedTableBlock
